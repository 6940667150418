import React from 'react';

const CaseStudyOutsourcing = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8756 12.3411C15.1497 12.3411 14.4401 12.1258 13.8365 11.7225C13.2329 11.3192 12.7623 10.746 12.4845 10.0753C12.2067 9.40463 12.134 8.6666 12.2756 7.95459C12.4172 7.24259 12.7669 6.58843 13.2802 6.07511C13.7935 5.56178 14.4474 5.21214 15.1594 5.07051C15.8714 4.92889 16.6095 5.00164 17.2802 5.27945C17.9509 5.55726 18.5243 6.0278 18.9276 6.63141C19.3309 7.23501 19.5462 7.94459 19.5462 8.67054C19.5462 9.15256 19.4512 9.63 19.2667 10.0753C19.0823 10.5206 18.8119 10.9251 18.4711 11.266C18.1302 11.6068 17.7255 11.8772 17.2802 12.0616C16.8348 12.2461 16.3576 12.3411 15.8756 12.3411ZM15.8756 5.96592C15.3407 5.96592 14.8177 6.12458 14.373 6.42176C13.9282 6.71894 13.5816 7.14133 13.3769 7.63553C13.1722 8.12972 13.1185 8.67367 13.2229 9.19831C13.3272 9.72294 13.5849 10.2048 13.9631 10.583C14.3414 10.9613 14.8232 11.2189 15.3479 11.3233C15.8725 11.4276 16.4164 11.374 16.9106 11.1693C17.4048 10.9646 17.8272 10.618 18.1244 10.1732C18.4216 9.72842 18.5802 9.20545 18.5802 8.67054C18.5828 8.31375 18.5146 7.96 18.3798 7.62963C18.245 7.29926 18.0464 6.99897 17.795 6.74578C17.5436 6.49259 17.2445 6.29147 16.9151 6.15434C16.5857 6.01722 16.2324 5.94657 15.8756 5.94658V5.96592Z"
      fill="#1A1A1A"
    />
    <path
      d="M14.8905 34.6797H13.5768C13.4487 34.6797 13.3259 34.6288 13.2353 34.5382C13.1447 34.4477 13.0938 34.3249 13.0938 34.1968V24.2284H11.3938C11.2721 24.2288 11.1548 24.1832 11.0653 24.1008C10.9757 24.0185 10.9206 23.9054 10.9108 23.7841L10.0029 14.4339C9.99151 14.3327 10.0137 14.2306 10.0661 14.1433C10.1185 14.0561 10.1981 13.9884 10.2927 13.9509L12.1088 13.159C12.2275 13.1169 12.3574 13.119 12.4745 13.1652C12.5917 13.2113 12.6882 13.2984 12.7462 13.4102C12.7928 13.5285 12.7928 13.66 12.7462 13.7783C12.6996 13.8966 12.6099 13.9929 12.4951 14.0476L11.0075 14.685L11.8383 23.2625H13.5768C13.7049 23.2625 13.8279 23.3134 13.9185 23.404C14.009 23.4946 14.0597 23.6174 14.0597 23.7455V33.7138H14.8905C15.0186 33.7138 15.1414 33.7645 15.232 33.8551C15.3226 33.9456 15.3735 34.0687 15.3735 34.1968C15.3735 34.3249 15.3226 34.4477 15.232 34.5382C15.1414 34.6288 15.0186 34.6797 14.8905 34.6797Z"
      fill="#1A1A1A"
    />
    <path
      d="M18.2325 34.6729H16.919C16.7909 34.6729 16.6679 34.622 16.5773 34.5315C16.4867 34.4409 16.436 34.3181 16.436 34.19C16.436 34.0619 16.4867 33.9389 16.5773 33.8483C16.6679 33.7577 16.7909 33.707 16.919 33.707H18.2325C18.3606 33.707 18.4834 33.7577 18.574 33.8483C18.6646 33.9389 18.7155 34.0619 18.7155 34.19C18.7155 34.3181 18.6646 34.4409 18.574 34.5315C18.4834 34.622 18.3606 34.6729 18.2325 34.6729Z"
      fill="#1A1A1A"
    />
    <path
      d="M20.9373 18.793C20.811 18.7784 20.6948 18.7167 20.6124 18.6199C20.5299 18.5231 20.4873 18.3984 20.493 18.2713L20.8406 14.6781L19.3533 14.0407C19.2948 14.0155 19.2417 13.9789 19.1974 13.9332C19.1531 13.8874 19.1184 13.8335 19.0951 13.7742C19.0717 13.715 19.0602 13.6516 19.0614 13.5879C19.0626 13.5243 19.0766 13.4616 19.1022 13.4033C19.1569 13.2885 19.2529 13.1988 19.3712 13.1521C19.4896 13.1055 19.6213 13.1055 19.7396 13.1521L21.5361 13.944C21.6307 13.9816 21.7103 14.0492 21.7627 14.1365C21.8151 14.2237 21.8373 14.3258 21.8259 14.427L21.4589 18.2907C21.4619 18.3591 21.4502 18.4275 21.4248 18.4911C21.3993 18.5547 21.3607 18.612 21.3113 18.6595C21.262 18.707 21.2032 18.7435 21.1387 18.7666C21.0742 18.7896 21.0056 18.7985 20.9373 18.793Z"
      fill="#1A1A1A"
    />
    <path
      d="M23.1009 34.2108C21.7322 34.2146 20.3931 33.8124 19.2532 33.0548C18.1134 32.2972 17.2241 31.2184 16.6976 29.955C16.1712 28.6916 16.0314 27.3004 16.296 25.9576C16.5607 24.6148 17.218 23.3807 18.1845 22.4116C19.1509 21.4424 20.383 20.7816 21.7251 20.5132C23.0672 20.2448 24.4588 20.3806 25.7237 20.9035C26.9885 21.4264 28.0698 22.3129 28.8306 23.4506C29.5914 24.5883 29.9974 25.9263 29.9974 27.2949C29.9974 29.1258 29.2716 30.8821 27.9788 32.1785C26.686 33.475 24.9317 34.2057 23.1009 34.2108ZM23.1009 21.3643C21.9224 21.3605 20.7693 21.7067 19.7878 22.359C18.8064 23.0113 18.0408 23.9402 17.5881 25.0282C17.1354 26.1162 17.016 27.3143 17.245 28.4703C17.4739 29.6262 18.041 30.6882 18.8743 31.5215C19.7076 32.3548 20.7695 32.9218 21.9255 33.1508C23.0815 33.3798 24.2795 33.2604 25.3676 32.8077C26.4556 32.355 27.3845 31.5894 28.0368 30.608C28.6891 29.6265 29.0353 28.4734 29.0315 27.2949C29.0264 25.7236 28.3999 24.2181 27.2888 23.107C26.1777 21.9959 24.6722 21.3694 23.1009 21.3643Z"
      fill="#1A1A1A"
    />
    <path
      d="M23.1008 31.1597C22.9727 31.1597 22.8499 31.1088 22.7593 31.0182C22.6687 30.9276 22.6178 30.8048 22.6178 30.6767V23.9346C22.6036 23.8645 22.6051 23.7921 22.6223 23.7226C22.6395 23.6532 22.6718 23.5882 22.7171 23.5328C22.7624 23.4774 22.8196 23.4328 22.8843 23.4022C22.949 23.3715 23.0195 23.3555 23.0911 23.3555C23.1627 23.3555 23.2335 23.3715 23.2981 23.4022C23.3628 23.4328 23.4198 23.4774 23.4651 23.5328C23.5104 23.5882 23.5427 23.6532 23.5599 23.7226C23.5771 23.7921 23.5786 23.8645 23.5644 23.9346V30.6961C23.5596 30.8175 23.5093 30.9327 23.4234 31.0187C23.3374 31.1046 23.2222 31.1549 23.1008 31.1597Z"
      fill="#1A1A1A"
    />
    <path
      d="M26.4815 27.794H19.7007C19.576 27.7891 19.4581 27.736 19.3717 27.6459C19.2853 27.5559 19.237 27.4359 19.2371 27.3111C19.237 27.1863 19.2853 27.0663 19.3717 26.9762C19.4581 26.8862 19.576 26.8331 19.7007 26.8281H26.4815C26.608 26.8329 26.7282 26.8852 26.8177 26.9748C26.9073 27.0644 26.9596 27.1845 26.9644 27.3111C26.9644 27.4392 26.9135 27.562 26.8229 27.6526C26.7323 27.7431 26.6095 27.794 26.4815 27.794Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default CaseStudyOutsourcing;
